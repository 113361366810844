.appBottomNavigation {
  display: none;
}

.mealDetailNoDialog {
  display: none;
}

@mixin responsive-rules {
  .App {
    overflow-y: auto;
    margin-bottom: 68px;
  }

  .App:has(.chat-container-no-navbar-mobile) {
    margin-bottom: initial;

    .mobile-navigation {
      display: none;
    }
  }

  .chat-container-no-navbar-mobile {
    height: 100vh;
  }

  .end-user-app {
    .centering-wrapper {
      max-width: 100%;
    }

    .my-day-view, .shared-programmes-view, .recipes-view, .recipes-search, .collections-view, .user-preferences-page {
      padding: initial;
    }

    .landing-page-centering-wrapper {
      max-width: 100%;    
    }

    .myDayDesktopBannerImage {
      display: none;
    }
  }

  .myDayHeroImage {
    display: block;
  }

  .appBottomNavigation {
    display: initial;
  }

  .topNavBar {
    display: none;
  }

  .topNavBarMobile {
    display: flex;
    position: relative;
  }

  .pingIndicator {
    display: none;
  }

  .plannerNavigationBar {
    display: none;
  }

  #smorg-board {
    .react-trello-board {
      scroll-snap-type: x mandatory;
      scroll-padding: calc((100vw - 350px) / 2);

      .smooth-dnd-draggable-wrapper, .non-draggable-lane {
        scroll-snap-align: start;
      }
    }
    // .react-trello-board::-webkit-scrollbar:horizontal {
    //   -webkit-appearance: none;
    // }
  }

  #planner-board {
    .react-trello-board {
      scroll-snap-type: x mandatory;
      scroll-padding: calc((100vw - 350px) / 2);

      .smooth-dnd-draggable-wrapper, .non-draggable-lane {
        scroll-snap-align: start;
      }
    }
    // .react-trello-board::-webkit-scrollbar:horizontal {
    //   -webkit-appearance: none;
    // }
  }

  #products-board {
    .react-trello-board {
      scroll-snap-type: x mandatory;
      scroll-padding: calc((100vw - 330px) / 2);

      .smooth-dnd-draggable-wrapper, .non-draggable-lane {
        scroll-snap-align: start;
      }
    }
  }

  #programme-board {
    .react-trello-lane > div > div {
      max-height: calc(var(--app-height) - 210px);
    }
  }
  
  #programme-board.with-fixed-header {
    .react-trello-lane > div > div {
      max-height: calc(var(--app-height) - 350px);
    }
  }
  
  .userProfile {
    // height: calc(var(--app-height) - 91px);
  }

  .mealInfoGrid {
    max-height: 140px;
    .mealInfoGridLabel {
      min-height: 35px;
      height: 35px;
      display: flex;
    }
    h5 {
      margin: auto 0;
    }
  }

  .mobile-actions-menu {
    display: initial;
  }

  .MuiDialog-root.darkBackgroundDialog .MuiDialog-paper.MuiPaper-root {
    background-color: var(--app-background-color);
  }

  .MuiDialog-container {
    .MuiDialog-paper {
      //max-height: calc(100% - 30px);
      //max-width: 900px;
      //width: calc(100% - 30px);
      width: 100%;
      height: 100%;
      max-height: 100%;
      margin: 0;
    }
  }

  .onboardingStep .decorationImage {
    display: none;
  }

  //#smorg-board {
  //  .react-trello-lane > div > div {
  //    /* Leave room for the expanded Add new meal form AND for the bottom nav bar*/
  //    max-height: calc(var(--app-height) - 220px);
  //  }
  //}
  .authForm {
    .authTeaser {
      max-width: 90%;
    }

    .logo-image {
      margin: 0;
    }
  }

  .signupDialog.withTeaser {
    .MuiDialog-paper {
      margin-top: 150px;
    }
  }

  .signupDialog.signupDialogAppTheme {
    .MuiDialog-paper {
      margin: 0;
    }
  }

  .programmes_request_access_intro_image {
    max-width: 300px;
  }

  .recipes-view, .shared-programmes-view, .user-preferences-page {
    min-height: calc(var(--app-height) - 69px);
  }
  .sharedProgrammeStartPage {
    height: calc(var(--app-height) - 0px);
  }
  .app-wizard {
    height: calc(var(--app-height) - 69px);
  }
  .my-day-view {
    min-height: calc(var(--app-height) - 69px);
  }
  .collections-view {
    min-height: calc(var(--app-height) - 69px);
  }
}

@media screen and (max-width: 799px) {
  @include responsive-rules;
}

.desktopMobilePreview {
  @include responsive-rules;
}

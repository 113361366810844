/* https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9 */
:root {
  --app-height: 100%;
  --app-background-color: #508f55;
  --app-background-image: none;
  // The primary color for the entire app, green for Smorg Studio
  --app-primary-color: #427a46;
  // The primary color for the space. It is different from --app-primary-color only inside Smorg Studio,
  // and is used for displaying app previews.
  --space-primary-color: #427a46;
  --card-background-color: #427a46;
  --app-primary-font-color: white;
  --app-navbar-font-color: white;
  --recipes-free-height: 69px; // a safe value
  --planner-free-height: 69px; // a safe value
}

/* Hack for react-trello + Material UI v5 */
.smooth-dnd-container * {
  box-sizing: inherit !important;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@font-face {
  font-family: 'Montserrat';
  src: url(../../fonts/Montserrat-Regular.ttf) format('truetype');
}

html {
  overscroll-behavior-y: none;
}

body {
  background-color: var(--app-background-color);
  background-image: var(--app-background-image);
}

a {
  color: var(--app-primary-font-color);
}

.smorg-studio, .end-user-community-app {  
  a {
    color: var(--app-background-color);
  }
}

.end-user-app {
  display: flex;
  margin-bottom: 68px;

  .centering-wrapper {
    margin: 0 auto;
    width: min(100vw, 1280px);
    box-shadow: 0px 4px 50px 40px #00000040;
    position: relative;

  }

  .landing-page-centering-wrapper {
    width: min(100vw, 640px);
    margin: 0 auto;
  }

  &:has(.landing-page-centering-wrapper) {
    margin-bottom: initial;
  }

  &:has(.smorg-board-container) {
    margin-bottom: initial;
  }
}

.MuiDialog-root .MuiPaper-root, .MuiPopover-root .MuiPaper-root {
  // Dialogs in Smorg Studio, End user community app and End user health pro app are all white-background
  background-color: white;
  a {
    color: var(--app-background-color);
  }
}

.MuiDialog-root.darkBackgroundAuthForm .MuiPaper-root, .MuiPopover-root.darkBackgroundAuthForm .MuiPaper-root {
  a {
    color: var(--app-primary-font-color);
  }
}

.light-background {
  // Content that is always on a light background irrespective of the theme.
  background-color: white;
  a {
    color: var(--app-background-color);
  }
}

.MuiBottomNavigation-root, .appPreview .MuiBottomNavigation-root {
  a {
    color: var(--app-navbar-font-color);
  }
}

.App {
  text-align: center;
  background-color: var(--app-background-color);
  overflow-y: hidden;
}

.topNavBar {
  background-color: var(--app-primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.topNavBar a,
.topNavBar > div {
  color: var(--app-primary-font-color);
}

.topNavBar .logo-image {
  width: 140px;
}

.topNavBar .sections {
  justify-content: flex-start;
  display: flex;
}

.topNavBar .sections a {
  text-decoration: none;
}

.topNavBar .sections a.current {
  text-decoration: underline;
  font-weight: bold;
}

.topNavBar .globalLinks {
  justify-content: flex-end;
  display: flex;
}

.topNavBar .sections a,
.topNavBar .globalLinks a,
.topNavBar .globalLinks div {
  margin: auto 5px;
}

.topNavBar .mealBasketWidget {
  width: 220px;
}

.popupTitle {
  position: relative;
}

.end-user-app {
  .appBottomNavigation {
    display: initial;
    max-width: 1280px;
  }

  .topNavBar {
    display: none;
  }

  .plannerNavigationBar {
    display: none;
  }

  .topNavBarMobile {
    display: flex;
    position: relative;
  }

  .my-day-view, .shared-programmes-view, .recipes-view, .recipes-search, .collections-view {
    padding: initial 55px;
  }
}

.actionsPopupMenu {
  svg {
    width: 24px;
    height: 24px;
    color: var(--app-primary-font-color);
  }
}
.topNavBarMobile {
  display: none;
  height: 44px;
  margin-top: 10px;
}

.topNavBarMobile, .appPreview {
  .logo-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 82px;
    height: 26px;
    margin-left: 10px;
    margin-top: 3px;
  }

  .screen-title {
    font-size: 32px;
    font-weight: 700;
    text-align: left;
    color: var(--app-primary-font-color);
    margin-left: 23px;
    margin-right: 23px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .screen-subtitle {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: var(--app-primary-font-color);
    margin-left: 23px;
    margin-right: 23px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .actionsPopupMenu {
    svg {
      width: 36px;
      height: 36px;
    }
  }  
}

.userProfile {
  // height: calc(var(--app-height) - 50px);
}

.react-trello-lane > div > div {
  overflow-y: auto;
  max-height: calc(var(--app-height) - 120px);
  overscroll-behavior-y: contain;
}

.mealNutritionDisclaimer {
  font-size: 12px;
  margin: 0 0 8px;
}

.myDayHeroImage {
  display: none;
  height: 210px;
  margin-top: 23px;
  margin-bottom: 0;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0% 50%;
}

.myDayDesktopBannerImage {
  height: 260px;
  margin-top: 23px;
  margin-bottom: 0;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0% 50%;
}

.meal-type-chip {
  font-size: 12px;
  border-radius: 4px;
  margin: 2px 2px 0 2px;

  span {
    padding: 0 2px;
  }
}

.meal-type-mini-chip {
  width: 28px;
  height: 18px;
  font-size: 12px;
  border-radius: 4px;
  margin: 2px 2px 2px 2px;

  span {
    padding: 0 2px;
  }
}

.non-draggable-lane {
  height: 100%;
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

#smorg-board {
  .react-trello-lane > div > div {
    max-height: calc(var(--app-height) - var(--recipes-free-height) - 72px);
  }

  .meal-type-chip {
    margin: 2px 2px 0 0;
  }
}

#planner-board {
  .react-trello-lane > div > div {
    /* Leave room for the 'Add new' button and the planner navigator */
    max-height: calc(var(--app-height) - var(--planner-free-height) - 105px);
  }
}

#programme-board {
  .react-trello-lane > div > div {
    max-height: calc(var(--app-height) - 190px);
  }
}

#programme-board.with-fixed-header {
  .react-trello-lane > div > div {
    max-height: calc(var(--app-height) - 330px);
  }
}

#space-content-board {
  .react-trello-lane > div > div {
    max-height: calc(var(--app-height) - 190px);
  }
}

#membership-tiers-board {
  .react-trello-lane > div > div.smooth-dnd-container {
    min-height: 240px;
  }
  .react-trello-lane > div > div {
    max-height: calc(var(--app-height) - 190px);
  }
  .react-trello-board {
    margin: auto;
  }

  & header ~ div {
    min-width: 360px;
    article {
      max-width: 360px;
    }
  }
}

.membershipTierForm {
  h5 {
    margin: 10px 0 5px;
    text-align: left;
  }

  .MuiGrid-item {
    padding: 0;
  }
}

.recipes-view {
  min-height: calc(var(--app-height) - 50px);
}

.meal-detail-page {
  min-height: calc(var(--app-height) - 50px);
}

.user-preferences-page {
  min-height: calc(var(--app-height) - 50px);
}

.shared-programmes-view {
  color: var(--app-primary-font-color);
  text-align: left;
  min-height: calc(var(--app-height) - 50px);

  .shared-programmes-page {
    overflow-y: scroll;
    max-height: calc(var(--app-height) - 73px);
  }

  a {
    color: var(--app-primary-font-color);
  }

  .MuiIconButton-root {
    color: var(--app-primary-font-color);
  }

  .timeline-card {
    width: 306px;
    max-height: 140px;
    background-color: white;
    color: black;
    margin: 12px 0 12px 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    overflow: hidden;
  }
}

.my-day-collections, .shared-programmes-view {
  .shared-programme-card {
    border-radius: 15px;
  }
}

.my-day-view, .collections-view, .collections-trello-view {
  color: var(--app-primary-font-color);
  text-align: left;
  min-height: calc(var(--app-height) - 50px);

  .popup-title-back-button button {
    color: var(--app-primary-font-color);
    margin-top: -4px;
  }
}

.topNavBarMobileLight button {
  color: var(--app-primary-font-color);
  margin-top: -4px;
}

.my-day-view, .appPreview, .recipes-search {

    
  .skippedCard {
    position: relative;
  }

  .skippedCardOverlay {
    z-index: 10;
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.82);
    display: flex;

    .skippedLabel {
      margin: auto;
      font-size: 24px;
      font-weight: bold;
      color: var(--app-primary-font-color);
    }
  }


  .myDayActionCardCompleted {
    font-size: 16px;
    font-weight: bold;
    color: #427A46;
  }

  .myDayContentEntryCardImage {
    height: 144px;
    width: 336px;
  }
}

.my-day-card {
  width: 336px;
  background-color: white;
  color: black;
  margin: 23px 16px 23px 23px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  overflow: hidden;
}

.my-day-compact-card {
  width: 244px;
  height: 275px;
  background-color: white;
  color: black;
  margin: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  overflow: hidden;
}

.my-day-compact-content-card {
  width: 244px;
  height: 200px;
  background-color: white;
  color: black;
  margin: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  overflow: hidden;
}

.myDayCompactContentEntryCardImage {
  height: 144px;
  width: 244px;
}

.myDayCompactContentEntryCardImage.cardWithNoTitle {
  height: 200px;
}

.narrowEndUserContentEntryCardImage {
  height: 98px;
  width: 166px;
}

.narrowEndUserContentEntryCardImage.cardWithNoTitle {
  height: 136px;
}

.recipes-view, .collections-view {
  .my-day-card {
    text-align: left;
    width: 336px;
    background-color: white;
    color: black;
    margin: 8px 16px 8px 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    overflow: hidden;
  }
}

.react-trello-board {
  font-family: 'Open Sans', sans-serif !important;
  background-color: var(--app-background-color) !important;
  section {
    border-radius: 15px;
    background-color: #ebecf0;
  }
  article {
    border: none;
    background-color: initial;
  }
  article > div {
    border-radius: 15px;
  }

}

.react-trello-board::-webkit-scrollbar {
  -webkit-appearance: none;
}
.react-trello-board::-webkit-scrollbar:horizontal {
  height: 8px;
}
.react-trello-board::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 1px solid var(--app-background-color);
  background-color: rgba(0, 0, 0, .5);
}
.react-trello-board::-webkit-scrollbar-track {
  background-color: var(--app-background-color);
  border-radius: 8px;
}

.react-trello-board .smooth-dnd-draggable-wrapper header + div {
  max-height: calc(var(--app-height) - 140px);
  overflow-y: clip;
}

#smorg-board, #planner-board {
  article {
    background-color: initial;
  }
  article > div {
    .MuiCardContent-root {
      width: 100%;
      margin: 8px 8px 0;
      padding: 0 0 8px;
    }
  }

  & header ~ div {
    min-width: 320px;
    article {
      max-width: 320px;
    }
  }
}

.react-trello-board section > header > div {
  vertical-align: middle;
}

.react-trello-board section > header {
  justify-content: space-between;
  padding: 0;
}

.react-trello-board section > header textarea {
  padding: 0;
}

.react-trello-card {
  padding: 0 !important;
}

.react-trello-lane header textarea {
  font-family: 'Open Sans', sans-serif;
}

:root {
  // --amplify-primary-color: #427a46;
  // --amplify-font-family: 'Open Sans', sans-serif;
  font-family: 'Open Sans', sans-serif;
  /*--amplify-primary-tint: #ff7359;*/
  /*--amplify-primary-shade: #e0573e;*/
}

body {
  margin: 0;
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Nunito Regular'), local('Nunito-Regular'),
    url(https://fonts.gstatic.com/s/nunito/v11/XRXV3I6Li01BKofINeaBTMnFcQ.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.popup-title-close-button {
  position: absolute;
  right: 5px;
  top: 5px;
}

.popup-title-back-button {
  position: absolute;
  left: 5px;
  top: 5px;
}

.authForm {
  text-align: center;
  height: 100vh;
  color: var(--app-primary-font-color);
  background-color: var(--app-background-color);
  background-image: var(--app-background-image);

  .logo-image {
    width: 136px;
    margin-top: 16px;
  }
}

.signupForm {
  text-align: center;
  // overflow: hidden;
  color: var(--app-primary-font-color);
  background-color: var(--app-background-color);
  background-image: var(--app-background-image);
}

.App:has(.darkBackgroundAuthForm) {
  margin-bottom: 0;
}

.darkBackgroundAuthForm {
  h2, p, a {
    color: var(--app-primary-font-color);
  }
}

.signupDialog {
  .MuiDialog-paper {
    margin-top: 200px;
  }
}

.signupDialog.signupDialogAppTheme {
  .MuiDialog-paper {
    margin: 0;
  }
}

.pingIndicator {
  display: flex;
  margin: 15px 0 auto;
  align-items: center;
  font-size: 14px;
  & svg {
    margin-right: 5px;
  }
}

.mealSubtitleGrid {
  .MuiGrid-root {
    height: 35px;
    display: flex;
  }

  .mealSubtitleGridLabel {
    margin: auto 0;
  }
}

.mealInfoGridLabel h5 {
  margin-bottom: 0;
}

.mobile-actions-menu {
  display: none;
}

.userProfile {
  background-color: white;
  text-align: left;

  .banner {
    background-color: #e1e4e9;
    padding: 5px;
    text-align: center;
  }

  .userProfileForm {
    max-width: 600px;
    width: 100%;
    margin: 10px auto;
    padding: 0 10px;

    h5 {
      margin: 10px 0 5px;
      text-align: left;
    }

    .MuiGrid-item {
      padding: 0;
    }
  }
}

.onboardingStep .decorationImage {
  display: flex;
  img {
    float: right;
  }
}

.modalSpinner {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  background: rgba(66, 122, 70, 0.9);
  height: 100vh;
  top: 0;
  z-index: 1101;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.newProgramme {
  background-color: white;
  text-align: left;

  .newProgrammeForm {
    max-width: 600px;
    width: 100%;
    margin: 10px auto;
    padding: 0 10px;

    h5 {
      margin: 10px 0 5px;
      text-align: left;
    }

    .MuiGrid-item {
      padding: 0;
    }
  }
}

.programmes_request_access_intro_image {
  max-width: 600px;
  margin: 0 auto;
  img {
    width: 100%;
  }
}

.marvel-device .screen {
  position: relative;

  // .scrollable-preview-content {
  //   /* Hide scrollbars */
  //   -ms-overflow-style: none;  /* Internet Explorer 10+ */
  //   scrollbar-width: none;  /* Firefox */
  //   &::-webkit-scrollbar { 
  //     display: none;  /* Safari and Chrome */
  //   }
  // }
}

.value {
  .metricQuantity, .metricUnit {
    font-weight: bold
  }
}

.mealCardNutrition {
  padding: 10px 0;

  .metricLabel {
    display: none;
  }

  .value {
    .metricQuantity, .metricUnit {
      font-size: 14px;
      font-weight: normal;
    }
  }
}

.stepInput {
  position: relative;
  margin: 0 auto;
  text-align: center;
  display: block;
  width: 75px;

  &.stepInputIsLoading {
    opacity: 0.5;
  }

  input {
    font-size: 16px;
    width: 70px;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    background: transparent;
    border: none;
    color: var(--card-background-color);
    text-align: center;
    line-height: 0;
    width: 50px;

    &:focus {
      outline: 0;
    }
  }

  .stepInputControls {
    svg {
      width: 24px;
      height: 24px;
    }

    .quantity-button {
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 100%;

      .svg-inline--fa {
        color: var(--card-background-color);
        font-size: 18px;
      }

      &.quantity-up {
        left: auto;
        right: 0;
      }
    }
  }
}

.mealCardServingsStepInput {
  margin: auto 0 !important;
}

.sharedProgrammeStartPage {
  background-color: white;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  text-align: initial;
  
  .programmeTitle, .programmeDescription {
    padding: 0 10px;
  }
}

.contentPage {
  background-color: white;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  text-align: initial;
}

.app-wizard, .onboarding-wizard {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;

  &-title {
    margin: 30px auto 10px;
    color: var(--app-primary-font-color);
    font-size: 24px;
    font-weight: bold;
    width: 348px;
  }

  .close-button {
    float: right;
    height: 16px;
    width: 16px;

    button {
      padding: 0;
      margin-top: -2px;
      margin-left: -2px;
    }
  }

  &-step {
    margin: 20px auto;
    padding: 20px;
    width: 308px;
    border-radius: 30px;
    background-color: white;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .step-heading {
      flex-grow: 0;
    }

    .step-content {
      flex-grow: 1;
    }

    .wizard-buttons {
      flex-grow: 0;
    }

    .completion-percentage {
      font-size: 14px;
      margin: 10px 0;
      font-weight: 100;
    }

    .step-intro {
      font-size: 20px;
      margin-bottom: 20px;
      font-weight: bold;
    }
  
    .step-title {
      font-size: 18px;
      font-weight: bold;
    }

    .step-paragraph {
      font-size: 14px;
      font-weight: 100;
    }
  }
}

.onboarding-wizard {
  height: calc(var(--app-height) - 36px);
}

.app-wizard {
  height: calc(var(--app-height) - 50px);
}

.subscription-screen {
  height: calc(100vh - 54px);
  overflow-y: auto;
  color: var(--app-primary-font-color);
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 27px;

  .screen-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 25px;
  }

  .spacer {
    flex-grow: 1;
  }
  .footer {
    text-align: center;
    display: flex;
    div {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
    }
  }
}

.onboarding-wizard-step .shared-programme-card {
  border-radius: 22px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.available-subscription {
  color: var(--app-primary-font-color);
  text-align: center;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin: 50px auto 0;
  min-width: 310px;
  max-width: 380px;
}

.subscription-name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.subscription-short-description {
  font-size: 16px;
  margin-bottom: 10px;
}

.subscribe-action {
  border-radius: 20px;
  background-color: var(--app-primary-color);
  width: 267px;
  height: 54px;
  margin: 10px auto;
  display: flex;
  cursor: pointer;

  &.is-loading {
    background-color: #e1e4e9;
    cursor: initial;
  }

  .subscribe-action-text {
    margin: auto;
    font-size: 16px;
    font-weight: bold;
    color: var(--app-primary-font-color);
  }
}

.smorg-companion-subscribe-screen .subscribe-action {
  height: 77px;
}

.recipes-search {
  text-align: left;

  .popup-title-back-button button {
    color: var(--app-primary-font-color);
  }

  .recipes-search-form {
    height: 100%;
    color: var(--app-primary-font-color);
  }

  .recipes-search-results {
    color: var(--app-primary-font-color);

    .popup-title-back-button svg, .popup-title-close-button svg {
      color: var(--app-primary-font-color);
    }
  }

  h2 {
    font-size: 20px;
  }
}

.landingPage {
  overscroll-behavior-y: none;
}

.landingPageCoverImage {
  height: 220px;
  background-size: contain;
}

.mobile-navigation {
  position: fixed;
  bottom: 0;
  border-radius: 0;
  background-color: var(--app-primary-color);
  width: min(100vw, 1280px);
}

.chat-container {
  height: calc(100vh - 69px);

  // // Hide the New conversation button unless disabled via component configuration
  // .cc-conversations__menus > cometchat-button {
  //   display: none;
  // }

  // // Hide the Conversation Info button unless disabled via component configuration
  // .cc-messages__header-menu-wrapper > cometchat-button {
  //   display: none;
  // }

  // Hide the Conversation Delete button
  .cc-conversations__options-view {
    display: none;
  }
}

.smorgStudioMemberships {
  margin: 10px 23px;
  text-align: left;
  background-color: white;
  border-radius: 15px;
  font-size: 14px;
  padding: 10px 20px;

  table {
    border-collapse: collapse;

    thead {     
      tr {
        border-bottom: 1px solid black;
      }

      th {
        padding: 6px;
        padding-right: 35px;
      }
    }

    tbody {
      overflow-y: scroll;
      color: #000000DE;

      td {
        padding: 6px;
        padding-right: 35px;
      }
    }
  }
}

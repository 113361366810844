.miq-block-list-item {
    margin: 10px 0;
    position: relative;
    border-radius: 4px;
}

.ingLabelChecked {
    text-decoration: line-through;
}

.ingTokenING {
    font-weight: bold;
    color: #427A46;
}

.ingTokenQTY {
    font-weight: bold;
}

.ingTokenUNITS {
    font-weight: bold;
    font-style: italic;
}

.ingLabelHeading {
    font-weight: bold;
    font-size: 18px;
}

.unlinked .ingTokenING.tokenHighlighted {
    color: red;
}

.suggestionsBoxContainer {
    width: 100%;
    position: relative;
    margin-top: -6px;
}

.suggestionsBox {
    z-index: 1;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.nutritionTable {
    width: 100%;
}

.nutritionTable ul {
    margin: 0;
    padding-left: 1em;
}

.scaledForWidget {
    font-size: initial;
}

.scaledForWidget input {
    margin-left: 5px;
    width: 50px;
    padding-top: 7px;
    padding-bottom: 5px;
    padding-left: 10px;
    font-size: 16px;
}

.suggestionHighlight {
    font-weight: bold;
    color: var(--app-primary-color);
}

.ingredientsEditModeWidget {
    display: inline;
    font-size: 16px;
    font-weight: normal;
    margin-left: 16px;
}

.ingredientsEditModeWidget svg {
    width: 20px;
    height: 20px;
    margin-left: 4px;
    margin-bottom: -4px;
}

@media screen and (max-width: 799px) {
    .adjustMarginOnMobile {
        margin-left: -5px;
        margin-right: 5px;
    }
}